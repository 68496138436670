import { Gender } from '@index/interfaces';

import { GthModel } from './model';

export class GthGenderModel extends GthModel<Gender> {
  get gender(): Gender {
    return this.model;
  }

  get uid() {
    return this.gender.uid;
  }

  get id() {
    return this.gender.id;
  }

  get label() {
    return this.gender.label;
  }

  get item() {
    return this.gender.item;
  }

  constructor(id: string, model: Gender) {
    super(id, model);
  }
}
