import { EventRating } from '@index/interfaces';

import { GthModel } from './model';

export class GthEventRatingModel extends GthModel<EventRating> {
  get eventRating(): EventRating {
    return this.model;
  }

  get uid() {
    return this.eventRating.uid;
  }

  get id() {
    return this.eventRating.id;
  }

  get label() {
    return this.eventRating.label;
  }

  get item() {
    return this.eventRating.item;
  }

  constructor(id: string, model: EventRating) {
    super(id, model);
  }
}
