import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TeamRole } from '@index/interfaces';
import { DBUtil } from '@index/utils/db-utils';

import { GthTeamRoleModel } from '../../models/team-role';
import { SrvCacheService } from '../cache.service';
import { CloudCollectionService } from './cloud-collection.service';

const COLLECTION_NAME = DBUtil.TeamRoles;

@Injectable({
  providedIn: 'root',
})
export class SrvTeamRolesService {
  private cloudCollection = new CloudCollectionService(this.firestore, COLLECTION_NAME);

  constructor(
    private firestore: AngularFirestore,
    private cache: SrvCacheService,
  ) {
  }

  /**
   * Gets a list of all team roles
   * @return {Promise<GthGendersModel[]>} Returns a list of team roles
   */
  async listAsync(): Promise<GthTeamRoleModel[]> {
    const store = this.cache.read<GthTeamRoleModel[]>(COLLECTION_NAME);
    if (store) return store;

    const items = await this.cloudCollection.list<TeamRole>();
    if (!items) return [];
    const models = items.map((i) => new GthTeamRoleModel(i.id.toString(), i));
    this.cache.store(COLLECTION_NAME, models);
    return models;
  }
}
