import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { EventRating } from '@index/interfaces';
import { DBUtil } from '@index/utils/db-utils';

import { GthEventRatingModel } from '../../models/event-rating';
import { SrvCacheService } from '../cache.service';
import { CloudCollectionService } from './cloud-collection.service';

const COLLECTION_NAME = DBUtil.EventRatings;

@Injectable({
  providedIn: 'root',
})
export class SrvEventRatingsService {
  private cloudCollection = new CloudCollectionService(this.firestore, COLLECTION_NAME);

  constructor(
    private firestore: AngularFirestore,
    private cache: SrvCacheService,
  ) {
  }

  /**
   * Gets a list of all event ratings
   * @return {Promise<GthEventRatingModel[]>} Returns a list of event ratings
   */
  async listAsync(): Promise<GthEventRatingModel[]> {
    const store = this.cache.read<GthEventRatingModel[]>(COLLECTION_NAME);
    if (store) return store;

    const items = await this.cloudCollection.list<EventRating>();
    if (!items) return [];
    const models = items.map((i) => new GthEventRatingModel(i.id.toString(), i));
    this.cache.store(COLLECTION_NAME, models);
    return models;
  }
}
